.login-component{
    height: 250px;
    width: 350px;
    margin: 0 auto;
    margin-top: 275px;
    padding: 5px;
    border-radius: 20px;
    /* background-color: rgba(49, 49, 49, 0.801); */
    /* border: 3px solid rgb(48, 48, 48); */
    align-content: center;
}

/* .center{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh; 
} */