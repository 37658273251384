.nav-bar-title{
    font-size: 20px;
}

.nav-bar-layout{
    height: 150px;
}

.nav-bar-logo{
    width: 120px;
    height: 100px;
}